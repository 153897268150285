.App {
  text-align: center;
  font-family: impact;
  background-color: #282c34;
  color: white
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Table {
  border-collapse: collapse;
  width: 100%;
  font-size: 1.2em;
  border: 2px solid white;
}
.Table th {
  border:1px solid white;
  
}
.Table td {
  border:1px solid white;
  text-align: center;
  border: 1px solid #ddd;
  padding: 8px;
}

a {
  color: red;
  text-decoration: none; /* Optionally remove underline */
}

a:hover {
  /* Optional hover effect */
  color: lightgray; /* Change color on hover */
}

body {
  background-color: #282c34;
}